.feedback {
  .ion-Toolbar {
    //--border-width: 0 !important;
    background: var(--ion-color-primary);
    border-radius: 0 0 0 50px;
    //position: absolute;

    ion-button {
      --box-shadow: none;
    }
  }

  .header {
    padding: 15px 20px;

    .media-body {
      display: flex;
      align-items: center;
      color: white;
      justify-content: space-between;

      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  .wrapper {
    background: var(--ion-color-primary);
    display: flex;
    flex-direction: column;
    height: 100%;

    .wrapper-inner {
      padding: 0 !important;
      background: white;
      height: 100%;
      border-radius: 0 50px 0 0;
    }
  }
}



.feedback-content {
  .wrapper {
    .wrapper-inner {
      ion-img {

        height: 250px;
        width: 250px;
        margin: 20px auto 20px auto;
      }
      .heading {
        text-align: center;
        padding: 0 30px;
        p{
          margin: 0;
          line-height: 20px;
        }
        h3{
          font-weight: 700;
          margin-bottom: 6px;
          color: var(--ion-color-primary);
        }
      }
      .user-feedback {
        display: flex;
        justify-content: center;
        .emojies{
          display: flex;

          margin: 20px;
          ion-button {
            height: 50px;
            width: 50px;
            --padding-start: 0;
            --padding-end: 0;
            --border-radius: 23px;
            --background: transparent;
            margin: 0 7px;

            ion-img {
              height:100%;
              width: 100%;
            }
          }
        }

      }
    }
  }
}

@media screen and (max-width: 280px) {
  .feedback-content {
    .wrapper {
      .wrapper-inner {
        .user-feedback {
          .emojies{
            ion-button {
              height: 34px;
              width: 34px;
            }
          }

        }
      }
    }
  }
}