* {
  font-family: Nunito;
}

.history-Content {
  .ion-Toolbar {
    //--border-width: 0 !important;
    background: var(--ion-color-primary);
    border-radius: 0 0 0 50px;
    //position: absolute;
    ion-button{
      --box-shadow: none;
    }
  }

  .header {
    padding: 40px 0 40px 0px;

    .media-body {
      display: flex;
      align-items: center;
      color: white;
      justify-content: center;
      flex-direction: column;

      h3 {
        margin: 10px 0 0 0;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  .wrapper {
    background: var(--ion-color-primary);
    display: flex;
    flex-direction: column;

    .wrapper-inner {
      background: white;
      height: 100%;
      border-radius: 0 50px 0 0;

      ion-list {
        margin-top: 50px;

        .history-list {
          margin-bottom: 20px;
          h1 {
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 10px 0;
          }
          ion-item:last-child {
            --border-style: none
          }
          ion-item {
            --padding-start: 0;

            .list {
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              padding: 10px 0;

              span {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.47);

              }

              .inner-ticket {
                display: flex;
                align-items: center;

                .date {
                  text-align: center;
                  margin-right: 20px;

                  p {
                    margin: 0;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.47);
                  }

                  h5 {
                    margin: 0;
                    font-size: 16px;
                    color: #706f6f;
                  }
                }

                ion-img {
                  width: 45px;
                  height: 45px;
                  background: #ededed;
                  padding: 10px;
                  border-radius: 50px;
                }

                .ticket-details {
                  padding-left: 20px;

                  p {
                    margin: 0;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.47);
                  }

                  h4 {
                    margin: 0 0 4px 0;
                    font-size: 16px;
                    color: #706f6f;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}