.login-content {
  .login-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-banner {
      text-align: center;

      ion-img {
        height: 100px;
        width: 100px;
        margin: 0 auto;
      }

      h1 {
        margin: 0;
        color: var(--ion-color-primary);
        font-weight: 700;
      }

      p {
        margin: 0;
      }
    }

    .login-form {
      padding: 0 30px;
      margin-top: 40px;
      width: 100%;

      form {
        a {
          float: right;
          color: var(--ion-color-primary);
        }

        ion-button {
          width: 100%;
          height: 48px;
          margin: 20px 0 0 0;
          --border-radius: 5px !important;
        }

        .email {
          ion-input {
            position: relative;
            border: 1px solid rgba(134, 134, 134, 0.29);
            --padding-start: 20px;
            height: 48px;
            border-radius: 5px;
            margin-bottom: 20px;
            font-size: 14px;
            color: #868686;

            ion-label {
              position: absolute;
              top: -10px;
              left: 34px;
              background: white;
              padding: 0 5px;
              font-size: 13px;
            }

            ion-icon {
              margin-left: 15px;
              color: var(--ion-color-secondary);
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}