* {
  font-family: Nunito;
}

.home-Content {
  .ion-Toolbar {
    //--border-width: 0 !important;
    background: var(--ion-color-primary);
    border-radius: 0 0 0 50px;
    //position: absolute;
  }

  .header {
    padding: 60px 0 80px 20px;
    .logout{
      display: flex;
      justify-content: flex-end;
      margin-right: 1rem;
      margin-bottom: 1rem;
     
      .logout-btn{
        --background: orange;
        font-size: 700;
        margin: 0 0 8px 0;
        font-weight: bold;
      }
    }
    .media-body {
      display: flex;
      align-items: center;

      .media-text {
        color: white;
        margin-left: 20px;

        h3 {
          margin: 0 0 8px 0;
          font-weight: 700;
          font-size: 20px !important;
        }

        p {
          margin: 0;
          font-size: 12px;
        }
      }

    }
  }

  .wrapper {
    background: var(--ion-color-primary);
    display: flex;
    flex-direction: column;

    .wrapper-inner {
      background: white;
      height: 100%;
      border-radius: 0 50px 0 0;

      .states {
        background: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        padding: 15px 0;
        margin: -43px 20px 0 20px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .stat-1 {
          text-align: center;

          h5 {
            margin: 0 !important;
            font-weight: 700;
            font-size: 20px;

          }

          p {
            margin: 0;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.47);
            font-size: 11px;
          }
        }
      }
    }

    .wrapper-buttons {
      background: white;
      margin-top: 50px;
      padding: 0 13px 0 8px;

      ion-row {
        justify-content: center;
        align-items: center;

        .history-list {

          a {
            text-decoration: none;
            background: var(--ion-color-primary);
            height: 128px;
            border-radius: 20px;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          p {
            color: white;
            margin-bottom: 0;
          }
        }

        ion-col {
          ion-button {
            height: 128px;
            --border-radius: 20px;
            width: 100%;

            ion-img {
              height: 48px;
              width: 48px;
            }

            p {
              margin-bottom: 0;
            }
          }
        }
      }

    }
  }
}