//* {
//  font-family: Nunito;
//}

.welcome-content {
  --background: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .banner {
    height: calc(100% - 65%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 50px 0;
    background: white;
    padding: 15px 0;

    ion-img {
      width: 200px;
      height: 200px;
    }
  }

  .content {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 50%);

    .content-inner {

      height: 100%;
      width: 100%;
      background: var(--ion-color-primary);
      border-radius: 50px 0 0 0;
      padding: 55px 20px 0 20px;
      text-align: center;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      //flex-direction: column;


      h1 {
        margin: 0;
        font-weight: bold;
        color: white;
        text-align: center;
      }

      p {
        color: white;
        text-align: center;
        margin: 10px 0 30px 0;
        line-height: 30px;
        font-size: 14px;
      }

      ion-grid {
        ion-row {
          ion-col {
            padding-right: 10px;
            padding-left: 10px;
            ion-button {
              height: 128px;
              --border-radius: 20px;
              width: 100%;
              --background: white;

              ion-img {
                height: 48px;
                width: 48px;
              }

              p {
                margin-bottom: 0;
                color: black;
                font-weight: bold;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}