* {
  font-family: Nunito;
}

.wrapper {
  .wrapper-inner {
    padding: 0 25px;

    .detail-cycle {
      height: 250px;
      width: 250px;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      border-radius: 50%;
      margin: 40px auto 30px auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      ion-img {
        width: 50px;
        height: 50px;
        margin: 0 auto;
      }

      ion-icon {
        font-size: 50px;
        margin-top: 30px;
      }

      h5 {
        font-weight: 700;
        font-family: Nunito;
        font-size: 23px;
        margin: 10px;
      }

      p {
        color: #bfbfbf;
        font-size: 12px;
        font-weight: 600;
        margin: 0;

        span {
          margin-left: 8px;
        }
      }
    }

    .comments {
      display: flex;
      align-items: center;
      justify-content: center;

      ion-input {
        background: #f5f5f5;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        --padding-start: 15px;
        height: 52px;
        font-size: 12px;
        //font-weight: 600;
        border-radius: 10px;
        color: #a1a0a0;
      }

      .tick-icon {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: 52px;
        width: 52px;
        display: flex;
        align-items: center;
        background: #f5f5f5;
        margin-left: 10px;
        border-radius: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        color: #a1a0a0;
      }
    }

    .cancel-queue {
      width: 100%;
      font-weight: 700;
      text-transform: capitalize;
      margin: 20px 0 20px 0;
      height: 52px;
      --background: var(--ion-color-secondary);
      --border-radius: 10px;
      //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
}
